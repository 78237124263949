import { Stack } from "@fluentui/react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styles from "./Chat.module.css";
import {
    handleTopicSelection
} from "./functions";
import { ChatState, useChatStore } from "../../store/Chat.store";
import { configuration } from "../../constants/configuration";

export const toggleContainer = () => {
    return (
        <Stack>
            <div className={`${styles.toggler}`} tabIndex={0}>
                <a href="https://os-staff-reporting.azurewebsites.net/" style={{ flex: 1, textAlign: 'right', fontWeight: "bold", textDecoration: "none", color: "#000" }}>Research Assistant</a>
                <img
                    style={{ flex: 1, maxWidth: '60px' }}
                    src='https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/toggler.svg'
                    className={styles.togglerIcon}
                    aria-hidden="true" />
                <a href="https://washoe-staff-report-general.azurewebsites.net/" style={{ flex: 1, textDecoration: "none", color: "#000" }}>Write a Staff Report</a>
            </div>
        </Stack>
    )
}

export const promptWrapperContainer = () => {
  const topicSelected = useChatStore((state: ChatState) => state.topicSelected);

  return (
      <Stack>
        <div className={styles.chatMessagePromptWrapper}>
          {configuration.prompts.topics.map((topic, index) => {
            return (
              <div
                className={`${
                  styles.chatMessagePromptMessageBlock
                } topicToggle ${
                  topicSelected?.title === topic.title ? "activeTopic" : ""
                }`}
                key={index}
                onClick={(_) => handleTopicSelection(topic)}
              >
                <div className={styles.chatMessagePromptMessageLeft}>
                  <h3 className={styles.buttonReportBlock}>{topic.title}</h3>
                  <p>{topic.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Stack>
  );
};

export const infoWrapperContainer = () => {
    return (
        <Stack>
            <div className={styles.infoContainer} tabIndex={0}>
                <div className={styles.infoColumn}>
                    <h4 className={styles.infoColumnHeader}><i className={styles.infoColumnIconSource}></i> What used as your data source?</h4>
                    <p>Madison has indexed your <strong>Board of County Commissioners</strong> file set, including board packets, agendas, meeting minutes, previous board action, codes, and statues.</p>
                    <p>Your database includes a library of ~3,884 files.</p>
                </div>
                <div className={styles.infoColumn}>
                    <h4 className={styles.infoColumnHeader}><i className={styles.infoColumnIconCreate}></i> What can Madison create?</h4>
                    <p>I’m an AI assistant to help research previous board action or prepare your next staff report. I can help you:</p>
                    <ul>
                        <li>Research and find all files related to a topic.</li>
                        <li>Summarize previous board action.</li>
                        <li>Draft a staff report to include motions, bid approvals, or grant approvals. </li>
                    </ul>
                </div>
            </div>
        </Stack>
    )
}

export const landingPageBrandingWrapper = () => {
  return (
    <>
      {configuration.header.visible && (
        <Stack className={styles.chatEmptyState}>
          <img
            src={configuration.header.logo}
            className={styles.chatIcon}
            aria-hidden="true"
          />
          {/* <div className={styles.chatEmptyStateTitle}>
            {configuration.header.title}
          </div> */}
          <div>
            <a data-tooltip-place="bottom" data-tooltip-id="info-tooltip" data-tooltip-html="Minutes, Staff Reports, Statutes, Master Plans from 2016-present. Updated monthly." target="_blank" href="https://osfiles.sharepoint.com/:f:/s/AI--DataIndexing/ErsKXIZNHd5FsfO2KJOSM6wBMVF1hy6cgCmM3egeJ66VkA?e=xIs4Ib" className={styles.chatEmptyStateSubtitle}><img src="https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-info.svg"></img>{configuration.header.subtitle}</a>
            {/* <ReactTooltip id="info-tooltip" className="tool__tips"/> */}
            {/* <span className={styles.chatEmptyStateSubtitle}>{configuration.header.description}</span> */}
          </div>
        </Stack>
      )}
    </>
  );
};

export const dialogTopicWrapper = (onCloseModal: () => void) => {
    return (
        <Stack>
            <div id="dialogTopic" className={styles.dialogTopic}>
                <div id="dialogHeaderTopic" className={styles.dialogHeaderTopic}>
                    <h3>Prompts</h3>
                    <button id="closeDialogButtonTopic" className={styles.closeDialogButtonTopic} onClick={_ => onCloseModal()}>X</button>
                </div>
                <p>Click these prompts to build the core components of a staff report. Use the checkmarks to mark each prompt set as complete.</p>
                <div className={styles.dialogItemsWrapper}>
                    <div className={styles.chatMessagePromptWrapper} style={{ paddingBottom: "0px" }}>

                        {
                            configuration.prompts.topics.map((topic, index) => {
                                return (
                                    <div key={index} className={`${styles.chatMessagePromptMessageBlock} topicToggle`}
                                        style={{ width: "265px" }}
                                        onClick={_ => handleTopicSelection(topic)}>
                                        <div className={styles.chatMessagePromptMessageLeft}>
                                            <h3 className={styles.buttonReportBlock}>{topic.title}</h3>
                                            <p>{topic.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div >
            </div >
        </Stack>
    )
}