export type Configuration = {
  header: {
    title: string;
    subtitle: string;
    description: string;
    logo: string;
    isThereMore: string;
    visible: boolean;
  };
  footer: {
    visible: boolean;
  };
  toggle: {
    visible: boolean;
  };
  prompts: {
    visible: boolean;
    topics: {
      title: string;
      description: string;
      userPrompt: string;
    }[];
  };
  info: {
    visible: boolean;
  };
};
// Comment
export const configuration: Configuration = {
  header: {
    title: "What information is in your index?",
    subtitle: "What information is in your index?",
    description:
      "This research assistant includes Board meeting minutes and packets from Jan 2016 - May 2024.",
    logo: "https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/logos/logo-nrs.png",
    visible: true,
    isThereMore: "Is there any more information on this topic?"
  },
  footer: {
    visible: true,
  },
  toggle: {
    visible: false,
  },
  prompts: {
    visible: true,
    topics: [
      {
        title: 'Summary of NRS',
        description: 'Summarizes a specific Nevada Revised Statute.',
        userPrompt: 'Summarize NRS {NRS number}.',
      },
      {
        title: 'Legislation Related to a Topic',
        description: 'Explains each NRS and how it relates to a specific topic.',
        userPrompt: 'Provide an overview of all legislation related to {topic}. Which specific NRS relates to {topic} and how?',
      },
      {
        title: 'Summarize NRS related to a topic',
        description: 'Provides a summary of each NRS related to a specific topic.',
        userPrompt: 'Provide an overview of all legislation related to {topic}. Reference the NRS for each topic.',
      },
      // {
      //   title: 'Researching meeting minutes.',
      //   description: 'Detailed list of presentations to the Board and votes, actions and funding allocations made on a topic.',
      //   userPrompt: 'Only research meeting minutes and provide a detailed list of all Board actions related to the {topic}. Include any stated motion, voting results, funding and any presentations made to the Board Include names. Sort by meeting date and reference specific meeting minutes.',
      // },
      // {
      //   title: 'Board Discussion',
      //   description: 'Presentations, Information Items, Staff Report & Board Action on a topic.',
      //   userPrompt: 'Research meeting minutes and provide a detailed list of all of the previous board actions and discussion. Include board votes, funding, presentations, informational items made to the board or any reference to an event that occurred related to the {topic} from {2015-2024}. Include date and title of most recently uploaded document.',
      // },
      // {
      //   title: 'County Code',
      //   description: 'Cites and summarizes specific chapters, sections and NRS related to a topic.',
      //   userPrompt: 'List ALL specific chapters for all statutes related to the {topic}. Cite the specific county code chapter, section and NRS. Include an overview of each chapter, section and NRS. Reference ordinances, provisions, regulations, administration, standards and requirements. Use section headers for easy reading. Sort by chapter. Reference documents used for research. Include date and title of most recently uploaded document.',
      // },
      // {
      //   title: 'Meeting Minutes',
      //   description: 'Detailed list of presentations to the board and votes, using only Minutes.',
      //   userPrompt: 'Show meeting minutes that include reference to the {topic} and provide a detailed list of all of the previous board actions to include board votes, funding, presentations made to the board or any reference to an event that occurred. Include date and title of most recently uploaded document.',
      // },
    ]
  },

  info: {
    visible: false,
  },
};
