import { Topic } from "../../models/topic";
import { useChatStore } from "../../store/Chat.store";

export const handleTopicSelection = (topicSelected: Topic) => {
    useChatStore.setState({ topicSelected, userPrompt: topicSelected.userPrompt});

    setTimeout(() => {
        const scrollDiv = document.getElementById('scrollDiv');
        if (scrollDiv) {
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
    }, 250);
}
