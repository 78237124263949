import { FormEvent, useEffect, useMemo, useState, useContext } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Checkbox, CommandBarButton, DefaultButton, Dialog, FontIcon, Stack, Text } from "@fluentui/react";
import DOMPurify from 'dompurify';
import { AppStateContext } from '../../state/AppProvider';
import styles from "./Answer.module.css";
import { AskResponse, Citation, Feedback, historyMessageFeedback } from "../../api";
import { parseAnswer } from "./AnswerParser";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import { ThumbDislike20Filled, ThumbLike20Filled } from "@fluentui/react-icons";
import { XSSAllowTags } from "../../constants/xssAllowTags";

const FILE_PATH_TRUNCATION_LIMIT = 50

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
}

export const Answer = ({
    answer,
    onCitationClicked
}: Props) => {
    const initializeAnswerFeedback = (answer: AskResponse): Feedback | undefined => {
        const { message_id, feedback } = answer;

        if (!message_id || !feedback) {
            return undefined;
        }

        if (feedback.includes(",")) {
            return Feedback.Negative;
        }

        if (Object.values(Feedback).includes(feedback)) {
            return feedback;
        }

        return Feedback.Neutral;
    }

    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(false);
    const [feedbackState, setFeedbackState] = useState(initializeAnswerFeedback(answer));
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [showReportInappropriateFeedback, setShowReportInappropriateFeedback] = useState(false);
    const [negativeFeedbackList, setNegativeFeedbackList] = useState<Feedback[]>([]);
    const appStateContext = useContext(AppStateContext)
    const FEEDBACK_ENABLED = appStateContext?.state.frontendSettings?.feedback_enabled && appStateContext?.state.isCosmosDBAvailable?.cosmosDB;

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
    };

    useEffect(() => {
        if (answer.message_id == undefined) return;

        let currentFeedbackState;
        if (appStateContext?.state.feedbackState && appStateContext?.state.feedbackState[answer.message_id]) {
            currentFeedbackState = appStateContext?.state.feedbackState[answer.message_id];
        } else {
            currentFeedbackState = initializeAnswerFeedback(answer);
        }
        setFeedbackState(currentFeedbackState)
    }, [appStateContext?.state.feedbackState, feedbackState, answer.message_id]);

    const createCitationUrl = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";
        let baseUrl = "https://osfiles.sharepoint.com/sites/AI--DataIndexing/Washoe%20%20BCC/Forms/AllItems.aspx?id=/sites/AI--DataIndexing/Washoe%20%20BCC/"
        let parentUrl = "&parent=/sites/AI--DataIndexing/Washoe%20%20BCC/"

        function removeAfterLastSlash(citationFilename: string): string {
            const lastSlashIndex = citationFilename.lastIndexOf("/");

            if (lastSlashIndex !== -1) {
                return citationFilename.substring(0, lastSlashIndex);
            } else {
                // No slash found, return the original filename
                return citationFilename;
            }
        }

        if (citation.filepath) {
            const part_i = citation.part_index ?? (citation.chunk_id ? parseInt(citation.chunk_id) + 1 : '');
            if (truncate && citation.filepath.length > FILE_PATH_TRUNCATION_LIMIT) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath}`;
            }
            else {
                citationFilename = `${citation.filepath}`;
            }
        }
        else if (citation.filepath && citation.reindex_id) {
            citationFilename = `${citation.filepath}`;
        }
        else {
            citationFilename = `${citation.filepath}`;
        }
        let finalUrl = removeAfterLastSlash(citationFilename.replace('03/2024/', ''));

        let citationUrl = `${baseUrl}${citationFilename.replace('03/2024/', '')}${parentUrl}${finalUrl}`;

        return citationUrl;
    }

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath) {
            const part_i = citation.part_index ?? (citation.chunk_id ? parseInt(citation.chunk_id) + 1 : '');
            if (truncate && citation.filepath.length > FILE_PATH_TRUNCATION_LIMIT) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${part_i}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${part_i}`;
            }
        }
        else if (citation.filepath && citation.reindex_id) {
            citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
        }
        else {
            citationFilename = `Citation ${index}`;
        }

        return citationFilename.replace('03/2024/', '');
    }

    const onLikeResponseClicked = async () => {
        if (answer.message_id == undefined) return;

        let newFeedbackState = feedbackState;
        // Set or unset the thumbs up state
        if (feedbackState == Feedback.Positive) {
            newFeedbackState = Feedback.Neutral;
        }
        else {
            newFeedbackState = Feedback.Positive;
        }
        appStateContext?.dispatch({ type: 'SET_FEEDBACK_STATE', payload: { answerId: answer.message_id, feedback: newFeedbackState } });
        setFeedbackState(newFeedbackState);

        // Update message feedback in db
        await historyMessageFeedback(answer.message_id, newFeedbackState);
    }

    const onDislikeResponseClicked = async () => {
        if (answer.message_id == undefined) return;

        let newFeedbackState = feedbackState;
        if (feedbackState === undefined || feedbackState === Feedback.Neutral || feedbackState === Feedback.Positive) {
            newFeedbackState = Feedback.Negative;
            setFeedbackState(newFeedbackState);
            setIsFeedbackDialogOpen(true);
        } else {
            // Reset negative feedback to neutral
            newFeedbackState = Feedback.Neutral;
            setFeedbackState(newFeedbackState);
            await historyMessageFeedback(answer.message_id, Feedback.Neutral);
        }
        appStateContext?.dispatch({ type: 'SET_FEEDBACK_STATE', payload: { answerId: answer.message_id, feedback: newFeedbackState } });
    }

    const updateFeedbackList = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (answer.message_id == undefined) return;
        let selectedFeedback = (ev?.target as HTMLInputElement)?.id as Feedback;

        let feedbackList = negativeFeedbackList.slice();
        if (checked) {
            feedbackList.push(selectedFeedback);
        } else {
            feedbackList = feedbackList.filter((f) => f !== selectedFeedback);
        }

        setNegativeFeedbackList(feedbackList);
    };

    const onSubmitNegativeFeedback = async () => {
        if (answer.message_id == undefined) return;
        await historyMessageFeedback(answer.message_id, negativeFeedbackList.join(","));
        resetFeedbackDialog();
    }

    const resetFeedbackDialog = () => {
        setIsFeedbackDialogOpen(false);
        setShowReportInappropriateFeedback(false);
        setNegativeFeedbackList([]);
    }

    const UnhelpfulFeedbackContent = () => {
        return (<>
            <div>Why wasn't this response helpful?</div>
            <Stack tokens={{ childrenGap: 4 }}>
                <Checkbox label="Citations are missing" id={Feedback.MissingCitation} defaultChecked={negativeFeedbackList.includes(Feedback.MissingCitation)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Citations are wrong" id={Feedback.WrongCitation} defaultChecked={negativeFeedbackList.includes(Feedback.WrongCitation)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="The response is not from my data" id={Feedback.OutOfScope} defaultChecked={negativeFeedbackList.includes(Feedback.OutOfScope)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Inaccurate or irrelevant" id={Feedback.InaccurateOrIrrelevant} defaultChecked={negativeFeedbackList.includes(Feedback.InaccurateOrIrrelevant)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Other" id={Feedback.OtherUnhelpful} defaultChecked={negativeFeedbackList.includes(Feedback.OtherUnhelpful)} onChange={updateFeedbackList}></Checkbox>
            </Stack>
            <div onClick={() => setShowReportInappropriateFeedback(true)} style={{ color: "#115EA3", cursor: "pointer" }}>Report inappropriate content</div>
        </>);
    }

    const ReportInappropriateFeedbackContent = () => {
        return (
            <>
                <div>The content is <span style={{ color: "red" }} >*</span></div>
                <Stack tokens={{ childrenGap: 4 }}>
                    <Checkbox label="Hate speech, stereotyping, demeaning" id={Feedback.HateSpeech} defaultChecked={negativeFeedbackList.includes(Feedback.HateSpeech)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Violent: glorification of violence, self-harm" id={Feedback.Violent} defaultChecked={negativeFeedbackList.includes(Feedback.Violent)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Sexual: explicit content, grooming" id={Feedback.Sexual} defaultChecked={negativeFeedbackList.includes(Feedback.Sexual)} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Manipulative: devious, emotional, pushy, bullying" defaultChecked={negativeFeedbackList.includes(Feedback.Manipulative)} id={Feedback.Manipulative} onChange={updateFeedbackList}></Checkbox>
                    <Checkbox label="Other" id={Feedback.OtherHarmful} defaultChecked={negativeFeedbackList.includes(Feedback.OtherHarmful)} onChange={updateFeedbackList}></Checkbox>
                </Stack>
            </>
        );
    }

    const toggleAccordionStatus = () => {
        setChevronIsExpanded(!chevronIsExpanded);
    }

    // Function to copy text to the clipboard

    // Function to copy text to the clipboard
    const copyToClipboard = (text: string): void => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }

    // Function to find the first child element with text content
    function findChildWithText(element: HTMLElement): HTMLElement | null {
        for (const child of Array.from(element.children)) {
            if (child.textContent?.trim()) {
                return child as HTMLElement;
            }
        }
        return null;
    }

    // Event listener for the parent element
    document.addEventListener('click', (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        // Check if the clicked element is the copy icon
        if (target && target.matches('div[aria-label="Copy this response"]')) {
            // Navigate to the previous element and parent to find the element with text content
            const previousElement = target.parentElement?.parentElement?.previousElementSibling;

            if (previousElement) {
                const answerTextDiv = findChildWithText(previousElement as HTMLElement);

                if (answerTextDiv) {
                    const text = answerTextDiv.textContent || '';
                    copyToClipboard(text);
                }
            }
        }
    });

    return (
        <>
            <Stack className={`${styles.answerContainer} static-class`} tabIndex={0}>
                <Stack.Item>
                    <Stack horizontal grow>
                        <Stack.Item grow>
                            <ReactMarkdown
                                linkTarget="_blank"
                                remarkPlugins={[remarkGfm, supersub]}
                                children={DOMPurify.sanitize(parsedAnswer.markdownFormatText, { ALLOWED_TAGS: XSSAllowTags })}
                                className={`${styles.answerText} content`}
                            />
                        </Stack.Item>
                    </Stack>

                </Stack.Item>

                <Stack.Item className={styles.answerHeader}>
                    {answer.message_id !== undefined && <Stack horizontal horizontalAlign="space-between">
                        <Stack
                            aria-hidden="false"
                            data-tooltip-id="copy-tooltip" 
                            data-tooltip-content="Copy"
                            className={styles.answerButton}
                            onClick={() => copyToClipboard(parsedAnswer.markdownFormatText)}
                            style={{ color: "transparent", cursor: "pointer", background: "url(https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-copy.svg) no-repeat center center", width: "20px", height: "20px", backgroundSize: "20px", marginRight: "10px", position: "relative", top: "-2px" }}
                        />
                        <ReactTooltip id="copy-tooltip"  className="tool__tips"/>
                        <Stack
                            aria-hidden="false"
                            aria-label="Like this response"
                            className={styles.answerButton}
                            onClick={() => onLikeResponseClicked()}
                            style={feedbackState === Feedback.Positive || appStateContext?.state.feedbackState[answer.message_id] === Feedback.Positive ?
                                { color: "transparent", cursor: "pointer", background: "url(https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-thumbs-up-selected.svg) no-repeat center center", width: "20px", height: "20px", marginRight: "10px" } :
                                { color: "transparent", cursor: "pointer", background: "url(https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-thumbs-up.svg) no-repeat center center", width: "20px", height: "20px", marginRight: "10px" }}
                        />
                        <Stack
                            aria-hidden="false"
                            aria-label="Dislike this response"
                            className={styles.answerButton}
                            onClick={() => onDislikeResponseClicked()}
                            style={(feedbackState !== Feedback.Positive && feedbackState !== Feedback.Neutral && feedbackState !== undefined) ?
                                { color: "transparent", cursor: "pointer", background: "url(https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-thumbs-down-selected.svg) no-repeat center center", width: "20px", height: "20px", marginRight: "10px" } :
                                { color: "transparent", cursor: "pointer", background: "url(https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-thumbs-down.svg) no-repeat center center", width: "20px", height: "20px", marginRight: "10px" }}
                        />
                    </Stack>}
                </Stack.Item>

                <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleAccordionStatus() : null}
                        >
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={_ => toggleAccordionStatus()}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " references" : "1 reference"}</span>
                                    </Text>
                                    <FontIcon className={styles.accordionIcon}
                                        onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                    />
                                </Stack>

                            </Stack>
                        </Stack.Item>
                    )}
                    <Stack.Item className={styles.answerDisclaimerContainer}>
                        <span className={styles.answerDisclaimer}>AI-generated content may be incorrect</span>
                    </Stack.Item>
                </Stack>
                {chevronIsExpanded &&
                    <div style={{ marginTop: 8, marginBottom: "20px", display: "flex", flexFlow: "wrap", maxHeight: "unset", gap: "4px", minWidth: "100%", paddingLeft: "30px" }}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                            // <a
                            //     href={createCitationUrl(citation, ++idx)} // Direct link creation
                            //     title={createCitationFilepath(citation, idx)}
                            //     tabIndex={0}
                            //     target="_blank" // Key addition for new tab
                            //     role="link"  // Maintain accessibility 
                            //     key={idx}
                            //     // onClick={() => onCitationClicked(citation)} // Preserve the click handler
                            //     className={styles.citationContainer}
                            //     aria-label={createCitationFilepath(citation, idx)}
                            // >
                            //     <div className={styles.citation}>{idx}</div>
                            //     {createCitationFilepath(citation, idx, true)}
                            // </a>);
                        })}
                    </div>
                }
            </Stack>
            <Dialog
                onDismiss={() => {
                    resetFeedbackDialog();
                    setFeedbackState(Feedback.Neutral);
                }}
                hidden={!isFeedbackDialogOpen}
                styles={{

                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '600px',
                                minHeight: '100px',
                            }
                        }
                    }]
                }}
                dialogContentProps={{
                    title: "Submit Feedback",
                    showCloseButton: true
                }}
            >
                <Stack tokens={{ childrenGap: 4 }}>
                    <div>Your feedback will improve this experience.</div>

                    {!showReportInappropriateFeedback ? <UnhelpfulFeedbackContent /> : <ReportInappropriateFeedbackContent />}

                    <div>By pressing submit, your feedback will be visible to the application owner.</div>

                    <DefaultButton disabled={negativeFeedbackList.length < 1} onClick={onSubmitNegativeFeedback}>Submit</DefaultButton>
                </Stack>

            </Dialog>
        </>
    );
};
